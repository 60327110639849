import React, {useState} from 'react';
import { useParams } from "react-router-dom";
import Ctrl from '../Ctrl';

const axios = require('axios')

export default function ControlPage(props) {
    let showModal = props.showModal;
    let closeModal = props.closeModal;
    let action = props.action;
    let ctrlType = props.ctrlType;
    let getLocalControl = props.getLocalControl;
    let setLocalControl = props.setLocalControl;
    let globalUploadObj = props.globalUploadObj;
    let setGlobalUploadObj = props.setGlobalUploadObj;
    let user = props.user;
    let users = props.users;
    let params = useParams();
    let id;

    const randString = (length) => {
        let result='',characters='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',charactersLength=characters.length;
        for (let i=0;i<length;i++){result += characters.charAt(Math.floor(Math.random() * charactersLength));}
        return result;
    }
    const stopObj = {
        name: '',
        hasVRMLogo: false,
        hasZielAnzeige: false,
        hasLinienNetzPlan: false,
        hasFahrplan: false,
        hasHSchild: false,
        hasBezeichnung: false,
        hasWerbeplakat: false,
        plannedDeparture: '',
        actualDeparture: '',
        missingOrWrongStopAnnouncement: false,
        missingOrWrongStopDisplay: false,
        noStopDespiteWaitingPassengers: false
    };
    const controlObj = (id) => {
        return {
            id: id,
            type: 'ctrl',
            plannedDate: new Date().toISOString().split('T')[0],
            line: '',
            owner: {
                user_id: ''
            },
            events: [{
                event: 'created',
                user_id: user._id,
                timestamp: new Date()
            }],
            licensePlate: '',
            tripId: '',
            startingIn: '',
            endingIn: '',
            headingTo: '',
            ctrlCategory: 'A',
            linienbuendel: 'null',
            vehicle: 'Bus',
            operator: '',
            stops: [stopObj],
            notes: '',
            workingTicketMachine: false,
            workingWagenHaeltSign: false,
            vehicleMeetsRequirements: false,
            hasWerbeplakat: false,
            hasVRMLogo: false,
            hasCorrectSignage: false,
            intactInterior: false,
            cleanInterior: false,
            workingAirCondition: false,
            tripCancelledWithoutReplacement: false,
            tripCancelledWithReplacement: false,
            inexperiencedStaff: false,
            staffSmoking: false,
            assistanceProvided: false,
            informationAvailable: false,
            staffOnPhoneWithoutEquipment: false
        }
    };
    const hsControlObj = (id) => {
        return {
            id: id,
            ctrlCategory: 'A',
            linienbuendel: 'null',
            type: 'hsctrl',
            events: [{
                event: 'created',
                user_id: user._id,
                timestamp: new Date()
            }],
            name: '',
            hasVRMLogo: false,
            hasZielAnzeige: false,
            hasLinienNetzPlan: false,
            hasFahrplan: false,
            hasHSchild: false,
            hasBezeichnung: false,
            hasWerbeplakat: false,
            noStopDespiteWaitingPassengers: false,
            notes: ''
        }
    }

    const newControl = () => {
        let tempId = randString(16);
        let tempGlobalUploadObj = {
            id: tempId,
            type: 'ctrl',
            obj: controlObj(tempId),
            action: 'wait'
        }
        setGlobalUploadObj(tempGlobalUploadObj)
        return tempId;
    }
    const newHsControl = () => {
        let tempId = randString(16);
        let tempGlobalUploadObj = {
            id: tempId,
            type: 'hsctrl',
            obj: hsControlObj(tempId),
            action: 'wait'
        }
        setGlobalUploadObj(tempGlobalUploadObj)
        return tempId;
    }

    const loadCtrl = (id) => {
        axios.get('https://vrm.fkmps.de/api/ctrls/'+id,{headers: {'Authorization': `Bearer ${user.accessToken}`}})
        .then((response) =>{
            if(response.data.isHidden){
                alert('Die Kontrolle, auf die Sie zugreifen möchten, ist auf dem Server als \'gelöscht\' markiert, aber noch verfügbar. Sollten Sie die Kontrolle ausversehen gelöscht haben, bitte eine kurze E-Mail schreiben.')
            }
            else{
                alert('Bitte aktualisieren Sie diese Seite. Nachdem dem Login sind alle auf dem Server gespeicherten Daten auch für Sie sichtbar.')
            }
        })
        .catch((err) => {
            if(err.response){
                if(err.response.status===404){
                    alert('Die angeforderte Kontrolle konnte auch auf dem Server nicht gefunden werden. Möglicherweise nutzen Sie einen falschen/veralteten Link!')
                }
            }
            else{
                alert('Beim Herunterladen der Kontrolle ist es zu einem Fehler gekommen.')
            }
        })
    }
    if(params.id){
        id = params.id;
    }
    else{
        if(!globalUploadObj.id){
            if(ctrlType==='ctrl'){
                newControl()
            }
            else{
                newHsControl()
            }
        }
        id = globalUploadObj.id;
    }
    if(id){
        if(action==='view'||action==='edit'){
            let localControl = getLocalControl(id);
            console.log(localControl)
            if(localControl){
                ctrlType = localControl.type;
                return <Ctrl users={users} id={id} localControl={localControl} ctrlType={ctrlType} action={action} showModal={showModal} closeModal={closeModal} stopObj={stopObj} setLocalControl={setLocalControl} globalUploadObj={globalUploadObj} setGlobalUploadObj={setGlobalUploadObj}/>
            }
            else{
                return (
                    <div className="content">
                        <div className="contentBox">Die angefragte Kontrolle ist lokal nicht verfügbar. Möchten Sie versuchen, die Kontrolle vom Server zu laden?</div>
                        <button className="extraButton noselect pointer" onClick={()=>loadCtrl(id)}>Kontrolle laden</button>
                    </div>
                );
            }
        }
        else{
            return <Ctrl users={users} id={id} ctrlType={ctrlType} action={action} showModal={showModal} closeModal={closeModal} stopObj={stopObj} globalUploadObj={globalUploadObj} setLocalControl={setLocalControl} setGlobalUploadObj={setGlobalUploadObj}/>
        }
    }
    else{
        if(params.id){
            id = params.id;
        }
        else{
            return <span>lädt...</span>
        }
    }

}
