export const linienbuendel = [
    {
        value: 'null',
        text: 'Bitte auswählen'
    },
    {
        value: 'EMS: Einrich',
        operator: 'Martin Becker GmbH & Co. KG',
        text: 'EMS: Einrich'
    },
    {
        value: 'EMS: Blaues Ländchen-Loreley',
        operator: 'Martin Becker GmbH & Co. KG',
        text: 'EMS: Blaues Ländchen-Loreley'
    },
    {
        value: 'EMS: Bad Ems-Nassau',
        operator: 'DB Regio Bus Rhein-Mosel GmbH (RMB)',
        text: 'EMS: Bad Ems-Nassau'
    },
    {
        value: 'EMS: Diez',
        operator: 'DB Regio Bus Rhein-Mosel GmbH (RMB)',
        text: 'EMS: Diez'
    },
    {
        value: 'AK: Altenkirchen',
        operator: 'Martin Becker GmbH & Co. KG',
        text: 'AK: Altenkirchen'
    },
    {
        value: 'AK: Altenkirchen-Wissen',
        operator: 'Westerwaldbus des Kreises Altenkirchen GmbH (WeBu)',
        text: 'AK: Altenkirchen-Wissen'
    },
    {
        value: 'AW: Rhein-Brohltal',
        operator: 'Ahrweiler Verkehrs GmbH (AWV)',
        text: 'AW: Rhein-Brohltal'
    },
    {
        value: 'AW: Hocheifel',
        operator: 'Verkehrsbetrieb Rhein-Eifel-Mosel GmbH (VREM)',
        text: 'AW: Hocheifel'
    },
    {
        value: 'AW: Rhein-Ahr',
        operator: 'DB Regio Bus Rhein-Mosel GmbH (RMB)',
        text: 'AW: Rhein-Ahr'
    },
    {
        value: 'AK: Betzdorf-Kirchen',
        operator: 'Westerwaldbus des Kreises Altenkirchen GmbH',
        text: 'AK: Betzdorf-Kirchen'
    },
    {
        value: 'COC: Treiser Schock',
        operator: 'Ahrweiler Verkehrs GmbH (AWV)',
        text: 'COC: Treiser Schock'
    },
    {
        value: 'AK: Daaden-Gebhardshain',
        operator: 'Westerwaldbus des Kreises Altenkirchen GmbH',
        text: 'AK: Daaden-Gebhardshain'
    },
    {
        value: 'AK: Hamm',
        operator: 'Marenbach Touristik GmbH & Co. KG',
        text: 'AK: Hamm'
    },
    {
        value: 'AK: Wildenburger Land',
        operator: 'Jung Bus GmbH',
        text: 'AK: Wildenburger Land'
    },
    {
        value: 'EMS: Lahnstein',
        operator: 'DB Regio Bus Rhein-Mosel GmbH (RMB)',
        text: 'EMS: Lahnstein'
    },
    {
        value: 'AK: Raiffeisen Region Nord',
        operator: 'Bischoff Touristik GmbH & Co. KG',
        text: 'AK: Raiffeisen Region Nord'
    },
    {
        value: 'MYK: Vordereifel',
        operator: 'Verkehrsbetrieb Rhein-Eifel-Mosel GmbH (VREM)',
        text: 'MYK: Vordereifel'
    },
    {
        value: 'MYK: Linke Rheinseite',
        operator: 'KVG Zickenheiner GmbH',
        text: 'MYK: Linke Rheinseite'
    },
    {
        value: 'MYK: Rechte Rheinseite',
        operator: 'KVG Zickenheiner GmbH',
        text: 'MYK: Rechte Rheinseite'
    },
    {
        value: 'MYK: Maifeld',
        operator: 'Verkehrsbetrieb Rhein-Eifel-Mosel GmbH (VREM)',
        text: 'MYK: Maifeld'
    },
    {
        value: 'MYK: Pellenz',
        operator: 'Verkehrsbetrieb Rhein-Eifel-Mosel GmbH (VREM)',
        text: 'MYK: Pellenz'
    },
    {
        value: 'EMS: Aartal',
        operator: 'DB Regio Bus Mitte GmbH (DRM)',
        text: 'EMS: Aartal'
    },
    {
        value: 'COC: Mosel-Maare',
        operator: 'DB Regio Bus Mitte GmbH (DRM)',
        text: 'COC: Mosel-Maare'
    },
    {
        value: 'COC: Blankenrath',
        operator: 'bkr-mobility & Scherer Reisen Omnibus Gesellschaft mbH',
        text: 'COC: Blankenrath'
    },
    {
        value: 'COC: Moselschleifen',
        operator: 'bkr-mobility GmbH (bkr) & DB Regio Bus Mitte GmbH (DRM) & Scherer Reisen Omnibus Gesellschaft mbH',
        text: 'COC: Moselschleifen'
    },
    {
        value: 'COC: Schieferland',
        operator: 'bkr-mobility GmbH (bkr) & DB Regio Bus Mitte GmbH (DRM)',
        text: 'COC: Schieferland'
    },
    {
        value: 'NR: Raiffeisen Region-Süd',
        operator: '',
        text: 'NR: Raiffeisen Region-Süd'
    },
    {
        value: 'NR: Wiedtal',
        operator: 'Bischoff Touristik GmbH & Co. KG',
        text: 'NR: Wiedtal'
    },
    {
        value: 'NR: Stadtverkehr Neuwied',
        operator: 'Mittelrheinischer Verkehrsbetrieb GmbH (MVB)',
        text: 'NR: Stadtverkehr Neuwied'
    },
    {
        value: 'SIM: Hunsrück Mitte',
        operator: 'Stemmler-Bus GmbH',
        text: 'SIM: Hunsrück Mitte'
    },
    {
        value: 'SIM: Hunsrückhöhenstraße Nord',
        operator: 'Zickenheiner GmbH',
        text: 'SIM: Hunsrückhöhenstraße Nord'
    },
    {
        value: 'SIM: Hunsrückhöhenstraße Süd',
        operator: 'bkr mobility GbR',
        text: 'SIM: Hunsrückhöhenstraße Süd'
    },
    {
        value: 'SIM: Oberes Mittelrheintal',
        operator: 'DB Regio Bus Mitte GmbH (DRM) & Scherer Reisen Omnibus Gesellschaft mbH',
        text: 'SIM: Oberes Mittelrheintal'
    }
]
