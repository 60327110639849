import React from 'react';
export default function AdminPage(props) {
    let user = props.user
    let showModal = props.showModal
    let closeModal = props.closeModal

    if(user.loggedIn && user.role === 'ADMIN'){
        return(
            <div className="content">
                <div className="contentBox">
                    
                </div>
            </div>
        )
    }
    else{
        return(
            <div className="content">
                <div className="contentBox">
                    Sie sind nicht berechtigt, auf diesen Bereich zuzugreifen.
                </div>
            </div>
        )
    }
}
