import React, { useState } from 'react';
import {useHistory} from "react-router-dom";
import ShowMore from './ShowMore';
const axios = require('axios')

export default function CtrlList(props) {
    let controls = props.controls;
    let setControls = props.setControls;
    let showModal = props.showModal;
    let closeModal = props.closeModal;
    let update = props.update;
    let deleteC = props.deleteC;
    let setGlobalUploadObj = props.setGlobalUploadObj;
    let user = props.user;
    let history = useHistory();
    let noCtrlsText = props.noCtrlsText;

    const [showMoreX, setShowMoreX] = useState({})
    const showMore = (id, options) => {
        setShowMoreX({
            visible: id,
            options: options
        })
    }
    const closeShowMore = () => {
        setShowMoreX({
            visible: false
        })
    }
    const deleteControl = (id) => {
        closeModal();
        closeShowMore();
        deleteC(id)
    }
    const downloadPDF = (id) => {
        axios.get('https://vrm.fkmps.de/api/ctrls/'+id+'/pdf',{responseType:'blob',headers: {'Authorization': `Bearer ${user.accessToken}`}})
        .then((response) =>{
            const file = new Blob([response.data], {type: 'application/pdf'});
            const fileURL = URL.createObjectURL(file);
            let pdfWindow = window.open(fileURL);
        })
        .catch((err) => {
            alert('Beim Download der PDF-Datei ist es zu einem Fehler gekommen.')
        })
    }
    const switchDraft = (id) => {
        let tempControls = [...controls];
        tempControls[controls.findIndex(x => x.id === id)].isDraft = !tempControls[controls.findIndex(x => x.id === id)].isDraft;
        setGlobalUploadObj({
            id: id,
            type: tempControls[controls.findIndex(x => x.id === id)].type,
            obj: tempControls[controls.findIndex(x => x.id === id)],
            action: 'update'
        })
        closeShowMore();
    }
    const getTextDate = (date) => {
        let today = new Date().toLocaleString();
        let xDate = new Date(date).toLocaleString()

        if(today.split('.')[0]===xDate.split('.')[0]&&today.split('.')[1]===xDate.split('.')[1]&&today.split('.')[2].substr(0,3)===xDate.split('.')[2].substr(0,3)){
            return 'Heute '+xDate.split(' ')[1].substr(0,5);
        }
        else{
            return ('0'+xDate.split('.')[0]).slice(-2)+'.'+('0'+xDate.split('.')[1]).slice(-2)+'.'+xDate.split('.')[2].substr(0,4)+' '+xDate.split(' ')[1].substr(0,5);
        }
    }
    if(controls.length !== 0){
    return (
        <div className="content">
            <div className="ctrlList">
                    {Object.entries(controls).reverse().map(([id, control])=>{
                        if(!control.isHidden){
                            let draftVar = '';
                            let draftOptions = {};
                            if(control.isDraft){
                                draftVar = <span className="isDraft"> Entwurf</span>;
                                draftOptions = {
                                    title: 'Entwurf-Markierung entfernen'
                                };
                            }
                            else{
                                draftOptions = {
                                    title: 'Als Entwurf markieren'
                                };
                            }
                            let modalContent = {text: 'Kontrolle löschen?',subText: 'Möchten Sie die Kontrolle wirklich löschen? Die eingegebenen Daten können ggf. nicht wiederhergestellt werden!',button: [{text: 'Nein', onClick: closeModal, ref: null},{text: 'Ja', onClick: ()=>deleteControl(control.id), ref: null}]};
                            let showMoreOptions = [
                                {
                                    title: 'Löschen',
                                    icon: 'delete',
                                    onClick: ()=>showModal(modalContent)
                                },
                                {
                                    title: 'PDF generieren',
                                    icon: 'picture_as_pdf',
                                    onClick: ()=>downloadPDF(control.id)
                                },
                                {
                                    title: draftOptions.title,
                                    icon: null,
                                    onClick: ()=>switchDraft(control.id)
                                }
                            ]
                            if(control.type==='hsctrl'){
                                return (
                                    <div className="ctrlListItem noselect" key={control.id}>
                                        <div className="ctrlListInfo" onClick={()=>history.push('/ctrls/'+control.id)}>{control.name}<br/><div className="ctrlListTime">{getTextDate(control.events[0].timestamp)}{draftVar}</div></div>
                                        <div className="ctrlListMore iconButton" onClick={()=>showMore(control.id,showMoreOptions)}><i className="material-icons">more_vert</i></div>
                                        <ShowMore visible={showMoreX.visible === control.id ? true : false} options={showMoreX.options} closeShowMore={closeShowMore}/>
                                    </div>
                                )
                            }
                            else{
                                return (
                                    <div className="ctrlListItem noselect" key={control.id}>
                                        <div className="ctrlListInfo" onClick={()=>history.push('/ctrls/'+control.id)}>{control.startingIn} - {control.endingIn}<br/><div className="ctrlListTime">{getTextDate(control.events[0].timestamp)}{draftVar}</div></div>
                                        <div className="ctrlListMore iconButton" onClick={()=>showMore(control.id,showMoreOptions)}><i className="material-icons">more_vert</i></div>
                                        <ShowMore visible={showMoreX.visible === control.id ? true : false} options={showMoreX.options} closeShowMore={closeShowMore}/>
                                    </div>
                                )
                            }
                        }
                    })}
            </div>
        </div>
    );
}
else{
    return <div className="content"><div className="contentBox">{noCtrlsText}</div></div>
}}
