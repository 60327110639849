import React, { useState } from 'react';
import CtrlList from '../CtrlList';

export default function AllControlsPage(props) {
    let controls = props.controls;
    let setControls = props.setControls;
    let showModal = props.showModal;
    let closeModal = props.closeModal;
    let update = props.update;
    let deleteC = props.deleteC;
    let setGlobalUploadObj = props.setGlobalUploadObj;
    let user = props.user;

    return (
        <CtrlList controls={controls} setControls={setControls} showModal={showModal} closeModal={closeModal} update={update} setGlobalUploadObj={setGlobalUploadObj} deleteC={deleteC} user={user} noCtrlsText="Es sind noch keine Kontrollen vorhanden."/>
    );
}
