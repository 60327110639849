import React, { useState, useEffect } from 'react';
const axios = require('axios')
export default function ContactPage(props) {
    const user = props.user
    const [serverStatus, setServerStatus] = useState('UNKNOWN');

    const getServerStatus = () => {
        axios.get('https://vrm.fkmps.de/api/status')
        .then((response) =>{
            if(response.data.status === 'ok'){
                setServerStatus('OK')
            }
        })
        .catch((err) => {
            alert('Der Server kann zurzeit nicht erreicht werden. Bitte prüfen Sie Ihre Internetverbindung.')
        })
    }
    useEffect(() => {
        getServerStatus()
    }, []);

    return (
        <div className="content">
            <div className="contentBox">
                <span>Logged in as:</span>
                <br/>
                <span>{user.name}</span>
                <br/>
                <span>{user.email}</span>
                <br/>
                <span>user_id: {user._id}</span>
                <br/>
                <span>user_role: {user.role}</span>
                <br/>
                <span>server_status: {serverStatus}</span>
                <br/>
                <span>client_version: v1.1.0</span>
            </div>
        </div>
    );
}
