import React from 'react';
import {Link, useParams} from "react-router-dom";
export default function AppBar(props) {
    let { id } = useParams();
    const data = props.data;
    let actionBtn;
    data.id === 'ViewControlPage' || data.id === 'EditControlPage' ? data.buttonTarget = data.buttonTarget.replace(':id', id) : console.log();
    data.id === 'EditControlPage' ? data.iconTarget = data.iconTarget.replace(':id', id) : console.log();
    data.button ? actionBtn = <Link to={data.buttonTarget} className="actionBtn noselect" onClick={data.buttonOnClick}>{props.data.buttonText}</Link> : actionBtn = <div></div>;
    return (
        <div className="appBar">
            <Link to={data.iconTarget} className="iconLink noselect" onClick={data.iconOnClick}><span className="material-icons icon">{data.icon}</span></Link>
            <h1 className="headText noselect">{data.title}</h1>
            {actionBtn}
        </div>
    );
}
