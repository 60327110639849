import React, { useState, useEffect } from 'react';
import LoadingSpinner from '../LoadingSpinner';
const axios = require('axios');

export default function LoginPage (props){
    const setControls = props.setControls;
    const setUsers = props.setUsers;
    const setUser = props.setUser;
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loginData, setLoginData] = useState({
        email: '',
        password: ''
    });
    const handleChange = (e) => {
        let tempLoginData = {...loginData};
        tempLoginData[e.target.id] = e.target.value;
        setLoginData(tempLoginData);
    }
    const togglePasswordVisible = () => {
        setPasswordVisible(!passwordVisible);
    }
    const login = (e) => {
        setLoading(true);
        axios.post('https://vrm.fkmps.de/api/login',{email: loginData.email, password: loginData.password})
        .then((response) => {
            let d = response.data
            if(d.accessToken){
                axios.get('https://vrm.fkmps.de/api/ctrls',{headers: {'Authorization': `Bearer ${d.accessToken}`}})
                .then((response) =>{
                    setControls(response.data)
                    d.user.loggedIn = true
                    d.user.accessToken = d.accessToken
                    setUser(d.user)
                })
                .catch((err) => {
                    alert('Beim Herunterladen der Kontrollen ist es zu einem Fehler gekommen.')
                })
                axios.get('https://vrm.fkmps.de/api/users',{headers: {'Authorization': `Bearer ${d.accessToken}`}})
                .then((response) =>{
                    setUsers(response.data)
                })
                .catch((err) => {
                    alert('Beim Herunterladen der Nutzer ist es zu einem Fehler gekommen.')
                })
            }
            else{
                alert('Email-Adresse oder Passwort falsch!')
            }
        })
        .catch((err) => {
            console.log(err)
            alert("Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.")
        })
        setLoading(false);
        e.preventDefault();
    }
    useEffect(() => {
        setLoading(false);
    },[])
    if(loading){
        return <LoadingSpinner/>
    }
    else{
        return (
            <div className="loginFormBox">
                <form className="loginForm" onSubmit={login}>
                    <h1 className="loginTitle noselect">VRM Login</h1>
                    <input type="email" id="email" className="input textInput loginInput" onChange={handleChange} placeholder="E-Mail-Adresse" autoComplete="username"></input>
                    <input type={passwordVisible ? 'text' : 'password'} id="password" className="input textInput loginInput" onChange={handleChange} placeholder="Passwort" autoComplete="current-password"></input>
                    <div className="showPasswordBox"><div className="showPassword noselect" onClick={togglePasswordVisible}>Passwort {passwordVisible ? 'verbergen' : 'anzeigen'}</div></div>
                    <button className="loginButton loginInput noselect">Einloggen</button>
                </form>
            </div>
        );
    }
}
