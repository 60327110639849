import React, {useState,useEffect} from 'react';
import FormEngine from './FormEngine';
import '../css/hsCard.css'
export default function HsCard (props){
    const stop = props.stop;
    const setStop = props.setStop;
    const deleteStop = props.deleteStop;
    const cardIndex = props.index;
    const showModal = props.showModal;
    const closeModal = props.closeModal;
    const action = props.action;
    const ctrlCategory = props.ctrlCategory;
    const id = props.id;
    const [expanded, setExpanded] = useState(false);
    useEffect(()=>{
        if(action==='view'||action==='edit'){
            setExpanded(false)
        }
        else{
            setExpanded(true)
        }
    },[action])
    // useEffect(()=>{
    //     let tempStop = {...stop};
    //     if(ctrlCategory==='B'){
    //         tempStop['missingOrWrongStopDisplay'] = null;
    //         setStop(cardIndex, tempStop,id)
    //     }
    //     else{
    //         tempStop['missingOrWrongStopDisplay'] = false;
    //         setStop(cardIndex, tempStop,id)
    //     }
    // },[ctrlCategory])


    const expandCard = (bool) => {
        setExpanded(bool)
    }
    const handleChange = (e) => {
        let tempStop = {...stop};
        tempStop[e.target.id.replace('-'+cardIndex,'')] = e.target.value;
        setStop(cardIndex,tempStop,id);
    }
    const handleCheck = (e) => {
        let tempStop = {...stop};
        tempStop[e.target.id.replace('-'+cardIndex,'')] = e.target.checked;
        setStop(cardIndex,tempStop,id);
    }

        if(!expanded){
            return(
                <div className="hsCard">
                    <div className="hsCardInfoBox">
                        <div className="hsCardTimeInfo">
                            <div className="hsCardTimeText">{stop.plannedDeparture}</div>
                            <div className="hsCardTimeText">{stop.actualDeparture}</div>
                        </div>
                        <div className="hsCardNameInfo">
                            <div className="hsNameInfoText">{stop.name}</div>
                        </div>
                    </div>
                    <span className="material-icons expandButton noselect" onClick={()=>expandCard(true)}>expand_more</span>
                </div>
            )
        }
        else{
            return(
                <div className="hsCardE">
                    <FormEngine data={stop} ctrlType="hscard" action={action} handleChange={handleChange} handleCheck={handleCheck} cardIndex={cardIndex} ctrlCategory={ctrlCategory}/>
                    <div className="cardButtonBar">
                        {action==='view' ? <span></span> : <span className="noselect deleteButton" onClick={()=>showModal({text: 'Haltestelle löschen?',subText: 'Möchten Sie die Haltestelle wirklich löschen? Die eingegebenen Daten können nicht wiederhergestellt werden!',button: [{text: 'Nein', onClick: closeModal, ref: null},{text: 'Ja', onClick: ()=>deleteStop(cardIndex), ref: null}]})}>löschen</span>}
                        <span className="material-icons expandButton noselect" onClick={()=>expandCard(false)}>expand_less</span>
                    </div>
                </div>
            )
        }
    }
