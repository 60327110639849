import React from 'react';
import { useHistory } from "react-router-dom";
export default function Modal(props) {
    let history = useHistory();
    let data = props.data;
    const buttonClick = (index) => {
        let btnObj = data.button[index];
        btnObj.onClick();
        if(btnObj.ref){history.push(btnObj.ref)};
    }
    if(props.modalVisible){
            return (
            <div className="modal" onClick={props.toggleMenu}>
                <div className="modalBlock">
                    <div className="modalText noselect">{data.text}</div>
                    <div className="modalSubText noselect">{data.subText}</div>
                    <div className="modalButtonBar">
                        <button className="modalButton noselect pointer" onClick={()=>buttonClick(0)}>{data.button[0].text}</button>
                        <button className="modalPrimaryButton noselect pointer" onClick={()=>buttonClick(1)}>{data.button[1].text}</button>
                    </div>
                </div>
            </div>
        )
    }
    else{
        return <div></div>
    }
}
