import React from 'react';
export default function ShowMore(props) {
    let options = props.options;
    let visible = props.visible;
    let closeShowMore = props.closeShowMore;
    if(visible){
        return (
            <div>
                <div className="showMoreCloser" onClick={closeShowMore}></div>
                <div className="showMore">
                    <div className="showMoreList">
                        {Object.entries(options).map(([id, item])=>{
                            return (
                                <div className="showMoreItem noselect" onClick={item.onClick} key={id}>
                                    {item.icon !== null ? <span className="material-icons showMoreIcon">{item.icon}</span> : <span></span>}{item.title}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }
    else{
        return <div></div>
    }
}
