import React from 'react';
import {ctrlSchema, hsCtrlSchema, hsCardSchema} from '../Schema'
export default function FormEngine(props) {
    const type = props.ctrlType;
    const action = props.action;
    const data = props.data;
    const users = props.users;
    const handleCheck = props.handleCheck;
    const handleChange = props.handleChange;
    const cardIndex = props.cardIndex;
    const ctrlCategory = props.ctrlCategory;

    let userSelectOptions = [{
        value: null,
        text: "Nicht zugewiesen"
    }]
    if(users){
        users.forEach((user) => {
            userSelectOptions.push({
                value: user._id,
                text: user.name
            })
        });
    }

    let autocomplete = (e) => {
        let suggestions = ["test", "test2", "test3"]
        console.log("Focus!")
        // return(
        //     <div className="autocompleteBox">
        //         {suggestions.map((suggestion, index) => {
        //             return(<div>{suggestion}</div>)
        //         })}
        //     </div>
        // )
    }



    let disabled;
    if(action==='create'||action==='edit'){
        disabled = false;
    }
    else{
        disabled = true;
    }
    if(type==='ctrl'){
        return (
            ctrlSchema.map((element, index) => {
                if(element.showWhen.includes(ctrlCategory)){
                    switch (element.type){
                        case 'text':
                            if(element.autocomplete!==undefined){
                                return (<div className="labelInputBox noselect" key={element.id}><label htmlFor={element.id}>{element.label}</label><input type="text" className="textInput input w-50" id={element.id} defaultValue={data[element.id]} onChange={handleChange} onFocus={autocomplete} placeholder={element.placeholder} autoComplete="off" disabled={disabled}/><div className="autocompleteBox autocompleteBoxHidden"></div></div>)
                            }
                            else{
                                return (<div className="labelInputBox noselect" key={element.id}><label htmlFor={element.id}>{element.label}</label><input type="text" className="textInput input w-50" id={element.id} defaultValue={data[element.id]} onChange={handleChange} placeholder={element.placeholder} autoComplete="off" disabled={disabled}/></div>)
                            }
                        case 'checkbox':
                            return (<div className="labelInputBox labelInputBoxCheck noselect" key={element.id}><label htmlFor={element.id}>{element.label}</label><input type="checkbox" className="textInput input checkbox" id={element.id} defaultChecked={data[element.id]} onChange={handleCheck} disabled={disabled}/></div>)
                        case 'date':
                            return (<div className="labelInputBox noselect" key={element.id}><label htmlFor={element.id}>{element.label}</label><input type="date" className="textInput input w-50" id={element.id} defaultValue={data[element.id]} onChange={handleChange} autoComplete="off" disabled={disabled} min={element.date.min}/></div>)
                        case 'select':
                            return (<div className="labelInputBox noselect" key={element.id}><label htmlFor={element.id}>{element.label}</label><select name={element.id} id={element.id} className="selectInput input w-50" defaultValue={data[element.id]} onChange={handleChange} disabled={disabled}>
                                {element.options.map((option, index) => {
                                    return <option value={option.value} key={element.id+'-'+option.value}>{option.text}</option>
                                })}
                                </select></div>)
                        case 'userSelect':
                            return (<div className="labelInputBox noselect" key={element.id}><label htmlFor={element.id}>{element.label}</label><select name={element.id} id={element.id} className="selectInput input w-50" defaultValue={data[element.id]['user_id']} onChange={handleChange} disabled={disabled}>
                                {userSelectOptions.map((option, index) =>{
                                    return <option value={option.value} key={element.id+'-'+option.value}>{option.text}</option>
                                })}
                                </select></div>)
                        case 'textarea':
                            return (<div className="labelInputBox noselect" key={element.id}><textarea className="textInput input w-100" id={element.id} defaultValue={data[element.id]} onChange={handleChange} placeholder={element.placeholder} autoComplete="off" disabled={disabled}></textarea></div>)
                        case 'hsName':
                            return (<div className="hsCardNameInputBox" key={element.id}><input type="text" className="textInput input hsCardNameInput" id={element.id} defaultValue={data[element.id]} onChange={handleChange} placeholder={element.placeholder} autoComplete="off" disabled={disabled}/></div>)
                        default:
                            return(<span key={element.id}>Dieses Input-Element ist nicht bekannt (FormEngine.js)</span>)
                    }
                }
                else{
                    return(<span key={element.id}></span>)
                }
            })
        )
    }
    else if(type==='hsctrl'){
        return (
            hsCtrlSchema.map((element, index) => {
                if(element.showWhen.includes(ctrlCategory)){
                    switch (element.type){
                        case 'text':
                            return (<div className="labelInputBox noselect" key={element.id}><label htmlFor={element.id}>{element.label}</label><input type="text" className="textInput input w-50" id={element.id} defaultValue={data[element.id]} value={data[element.id]} onChange={handleChange} placeholder={element.placeholder} autoComplete="off" disabled={disabled}/></div>)
                        case 'checkbox':
                            return (<div className="labelInputBox labelInputBoxCheck noselect" key={element.id}><label htmlFor={element.id}>{element.label}</label><input type="checkbox" className="textInput input checkbox" id={element.id} defaultChecked={data[element.id]} onChange={handleCheck} disabled={disabled}/></div>)
                        case 'select':
                            return (<div className="labelInputBox noselect" key={element.id}><label htmlFor={element.id}>{element.label}</label><select name={element.id} id={element.id} className="selectInput input w-50" defaultValue={data[element.id]} onChange={handleChange} disabled={disabled}>
                                {element.options.map((option, index) =>{
                                    return <option value={option.value} key={element.id+'-'+option.value}>{option.text}</option>
                                })}
                                </select></div>)
                        case 'textarea':
                            return (<div className="labelInputBox noselect" key={element.id}><textarea className="textInput input w-100" id={element.id} defaultValue={data[element.id]} onChange={handleChange} placeholder={element.placeholder} autoComplete="off" disabled={disabled}></textarea></div>)
                        case 'hsName':
                            return (<div className="hsCardNameInputBox" key={element.id}><input type="text" className="textInput input hsCardNameInput" id={element.id} defaultValue={data[element.id]} onChange={handleChange} placeholder={element.placeholder} autoComplete="off" disabled={disabled}/></div>)
                        default:
                            return(<span key={element.id}>Dieses Input-Element ist nicht bekannt (FormEngine.js)</span>)
                    }
                }
                else{
                    return(<span key={element.id}></span>)
                }
            })
        )
    }
    else{
        return (
            hsCardSchema.map((element, index) => {
                if(element.showWhen.includes(ctrlCategory)){
                    switch (element.type){
                        case 'text':
                            return (<div className="labelInputBox noselect" key={element.id+'-'+cardIndex}><label htmlFor={element.id+'-'+cardIndex}>{element.label}</label><input type="text" className="textInput input w-50" id={element.id+'-'+cardIndex} defaultValue={data[element.id]} onChange={handleChange} placeholder={element.placeholder} autoComplete="off" disabled={disabled}/></div>)
                        case 'checkbox':
                            return (<div className="labelInputBox labelInputBoxCheck noselect" key={element.id+'-'+cardIndex}><label htmlFor={element.id+'-'+cardIndex}>{element.label}</label><input type="checkbox" className="textInput input checkbox" id={element.id+'-'+cardIndex} defaultChecked={data[element.id]} onChange={handleCheck} disabled={disabled}/></div>)
                        case 'hsName':
                            return (<div className="hsCardNameInputBox" key={element.id+'-'+cardIndex}><input type="text" className="textInput input hsCardNameInput" id={element.id+'-'+cardIndex} defaultValue={data[element.id]} onChange={handleChange} placeholder={element.placeholder} autoComplete="off" disabled={disabled}/></div>)
                        case 'textarea':
                            return (<div className="labelInputBox noselect" key={element.id+'-'+cardIndex}><textarea className="textInput input w-100" id={element.id+'-'+cardIndex} defaultValue={data[element.id]} onChange={handleChange} placeholder={element.placeholder} autoComplete="off" disabled={disabled}></textarea></div>)
                        default:
                            return(<span key={element.id+'-'+cardIndex}>Dieses Input-Element ist nicht bekannt (FormEngine.js)</span>)
                    }
                }
                else{
                    return(<span key={element.id+'-'+cardIndex}></span>)
                }
            })
        )
    }
}
