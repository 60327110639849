import React from 'react';
export default function LoadingSpinner (props){
    return (
        <div className="loadingSpinnerBox">
            <div className="loadingSpinner">
                <div></div>
                <div></div>
            </div>
            <div className="loadingSpinnerText">
                loading
            </div>
        </div>
    )
}
