import React, { useState, useEffect } from 'react';
import './App.css';
import './css/basic.css'
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";

import HomePage from './components/pages/HomePage';
import ControlPage from './components/pages/ControlPage';
import InfoPage from './components/pages/InfoPage';
import AllControlsPage from './components/pages/AllControlsPage';
import AdminPage from './components/pages/AdminPage';
import LoginPage from './components/pages/LoginPage';

import Menu from './components/Menu';
import AppBar from './components/AppBar';
import MenuCloser from './components/MenuCloser';
import Modal from './components/Modal';

const axios = require('axios')

export default function App() {
    const currentDate = () => {
        let today = new Date(),
        dd = String(today.getDate()).padStart(2, '0'),
        mm = String(today.getMonth() + 1).padStart(2, '0'),
        yyyy = today.getFullYear(),
        h = String(today.getHours()).padStart(2, '0'),
        m = String(today.getMinutes()).padStart(2, '0'),
        s = String(today.getSeconds()).padStart(2, '0');
        today = yyyy + '-' + mm + '-' + dd + 'T' + h + ':' + m + ':' + s;
        return today;
    }
    const [menuVisible, setMenuVisible] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalData, setModalData] = useState({});
    const [controls, setControls] = useState([]);
    const [users, setUsers] = useState([]);
    const [user, setUser] = useState({
        id: null,
        name: null,
        email: null,
        role: null,
        accessToken: null,
        loggedIn: false
    })
    const [globalUploadObj, setGlobalUploadObj] = useState({
        id: null,
        type: null,
        obj: {},
        action: 'wait'
    });
    const randString = (length) => {
        let result='',characters='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',charactersLength=characters.length;
        for (let i=0;i<length;i++){result += characters.charAt(Math.floor(Math.random() * charactersLength));}
        return result;
    }
    const addLocalControl = (id, control) => {
        let tempControls = [...controls];
        tempControls.push(control);
        setControls(tempControls);
    }
    const getLocalControl = (id) => {
        if(controls.find(x => x.id === id)){
            return controls.find(x => x.id === id)
        }
        else{
            return false
        }
    }
    const setLocalControl = (id, control) => {
        if(controls.find(x => x.id === id)){
            let tempControls = [...controls]
            tempControls[controls.findIndex(x => x.id === id)] = control;
            setControls(tempControls)
        }
        else{
            return false
        }
    }
    const deleteLocalControl = (id) => {
        if(controls.find(x => x.id === id)){
            setControls(controls.filter(x => x.id !== id))
        }
        else{
            return false
        }
    }

    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    }
    const showModal = (options) => {
        setModalData(options);
        setModalVisible(true);
    }
    const logout = () => {
        setUser({
            id: null,
            name: null,
            email: null,
            role: null,
            accessToken: null,
            loggedIn: false
        })
        setMenuVisible(false);
    }
    const closeModal = () => {
        setModalVisible(false);
    }
    const saveAsDraft = () => {
        globalUploadObj.obj.isDraft = true;
        closeModal();
        upload();
    }
    const clearObjCloseModal = () => {
        closeModal()
        clearUploadObj()
    }
    const askSaveAsDraft = () => {
        showModal({
            text: 'Als Entwurf speichern?',
            subText: 'Möchten Sie die angefangene Kontrolle als Entwurf speichern? Sie können diese jederzeit unter dem Menüpunkt "Alle Kontrollen" aufrufen, bearbeiten oder löschen.',
            button: [
                {
                    text: 'Nein',
                    onClick: clearObjCloseModal,
                    ref: '/'
                },
                {
                    text: 'Ja',
                    onClick: saveAsDraft,
                    ref: '/'
                }
            ]
        })
    }

    useEffect(() => {
        if(user.accessToken&&globalUploadObj.id){
            if(globalUploadObj.action==='upload'){
                upload()
            }
            else if(globalUploadObj.action==='update'){
                update()
            }
        }
    }, [globalUploadObj.id,globalUploadObj.action]);

    const upload = () => {
        if(user.accessToken){
            let params;
            let data = globalUploadObj.obj
            data.sent = new Date()
            data.events.push({
                event: 'sent',
                user_id: user._id,
                timestamp: new Date()
            })
            if(globalUploadObj.type==='ctrl'||globalUploadObj.type==='hsctrl'){
                setControls(controls.concat(globalUploadObj.obj));

                axios.post('https://vrm.fkmps.de/api/ctrls',{data: data}, {headers: {'Authorization': `Bearer ${user.accessToken}`}})
                .then((response) =>{
                })
                .catch((err) => {
                    alert('Beim Uploaden der Kontrolle ist es zu einem Fehler gekommen.')
                })
            }
            else{
                alert('Upload konnte nicht ausgeführt werden. globalUploadObj.type unbekannt. (App.js)')
            }
        }
        else{
            alert('AccessToken not available! (App.js)')
        }
        clearUploadObj();
    }
    const update = () => {
        if(user.accessToken){
            let params;
            if(globalUploadObj.type==='ctrl'||globalUploadObj.type==='hsctrl'){
                let tempControls = [...controls];
                tempControls[controls.findIndex(x => x.id === globalUploadObj.id)] = globalUploadObj.obj;
                setControls(tempControls);

                axios.put('https://vrm.fkmps.de/api/ctrls/'+globalUploadObj.id,{data: globalUploadObj.obj}, {headers: {'Authorization': `Bearer ${user.accessToken}`}})
                .then((response) =>{
                })
                .catch((err) => {
                    alert('Beim Updaten der Kontrolle ist es belastenderweise zu einem Fehler gekommen :(')
                })
            }
            else{
                alert('Update konnte nicht ausgeführt werden. globalUploadObj.type unbekannt. (App.js)')
            }
        }
        else{
            alert('AccessToken not available! (App.js)')
        }
        clearUploadObj();
    }
    const deleteC = (id) => {
        axios.delete('https://vrm.fkmps.de/api/ctrls/'+id, {headers: {'Authorization': `Bearer ${user.accessToken}`}})
        .then((response) =>{
            deleteLocalControl(id)
        })
        .catch((err) => {
            alert(err)
            alert('Beim Löschen der Kontrolle ist es zu einem Fehler gekommen. Bitte prüfen Sie Ihre Internetverbindung und veruschen es erneut.')
        })
    }
    const clearUploadObj = () => {
        setGlobalUploadObj({
            id: null,
            type: null,
            obj: {},
            action: 'wait'
        });
    }
    const uploadObjUpload = () => {
        let tempGlobalUploadObj = {...globalUploadObj};
        tempGlobalUploadObj.action = 'upload';
        setGlobalUploadObj(tempGlobalUploadObj)
    }
    const uploadObjUpdate = () => {
        let tempGlobalUploadObj = {...globalUploadObj};
        tempGlobalUploadObj.action = 'update';
        setGlobalUploadObj(tempGlobalUploadObj)
    }
    const pages = [
        {
            id: 'HomePage',
            url: '/',
            title: 'Startseite',
            icon: 'menu',
            iconTarget: '/',
            iconOnClick: toggleMenu,
            button: true,
            buttonText: '+Kontrolle',
            buttonTarget: '/ctrl/new',
            buttonOnClick: null,
            component: <HomePage controls={controls} setControls={setControls} showModal={showModal} closeModal={closeModal} update={update} setGlobalUploadObj={setGlobalUploadObj} deleteC={deleteC} user={user}/>
        },
        {
            id: 'AddControlsPage',
            url: '/ctrl/new',
            title: 'Kontrolle',
            icon: 'close',
            iconTarget: '/ctrl/new',
            iconOnClick: askSaveAsDraft,
            button: true,
            buttonText: 'Speichern',
            buttonTarget: '/',
            buttonOnClick: uploadObjUpload,
            component: <ControlPage users={users} user={user} showModal={showModal} closeModal={closeModal} action='create' ctrlType='ctrl' getLocalControl={getLocalControl} setLocalControl={setLocalControl} globalUploadObj={globalUploadObj} setGlobalUploadObj={setGlobalUploadObj}/>
        },
        {
            id: 'InfoPage',
            url: '/info',
            title: 'Information',
            icon: 'close',
            iconTarget: '/',
            iconOnClick: null,
            button: false,
            component: <InfoPage user={user}/>
        },
        {
            id: 'AddHsControlPage',
            url: '/hsctrl/new',
            title: 'Haltestelle melden',
            icon: 'close',
            iconTarget: '/hsctrl/new',
            iconOnClick: askSaveAsDraft,
            button: true,
            buttonText: 'Speichern',
            buttonTarget: '/',
            buttonOnClick: uploadObjUpload,
            component: <ControlPage users={users} user={user} showModal={showModal} closeModal={closeModal} action='create' ctrlType='hsctrl' getLocalControl={getLocalControl} setLocalControl={setLocalControl} globalUploadObj={globalUploadObj} setGlobalUploadObj={setGlobalUploadObj}/>
        },
        {
            id: 'AllControlsPage',
            url: '/ctrls',
            title: 'Alle Kontrollen',
            icon: 'close',
            iconTarget: '/',
            iconOnClick: null,
            button: false,
            component: <AllControlsPage controls={controls} setControls={setControls} showModal={showModal} closeModal={closeModal} update={update} setGlobalUploadObj={setGlobalUploadObj} deleteC={deleteC} user={user}/>
        },
        {
            id: 'AdminPage',
            url: '/admin',
            title: 'Admin-Panel',
            icon: 'close',
            iconTarget: '/',
            iconOnClick: null,
            button: false,
            component: <AdminPage user={user} showModal={showModal} closeModal={closeModal}/>
        },
        {
            id: 'ViewControlPage',
            url: '/ctrls/:id',
            title: 'Kontrolle ansehen',
            icon: 'arrow_back',
            iconTarget: '/ctrls',
            iconOnClick: clearUploadObj,
            button: true,
            buttonText: 'Bearbeiten',
            buttonTarget: '/ctrls/:id/edit',
            buttonOnClick: null,
            component: <ControlPage users={users} user={user} showModal={showModal} closeModal={closeModal} action='view' getLocalControl={getLocalControl} setLocalControl={setLocalControl} globalUploadObj={globalUploadObj} setGlobalUploadObj={setGlobalUploadObj}/>
        },
        {
            id: 'EditControlPage',
            url: '/ctrls/:id/edit',
            title: 'Kontrolle bearbeiten',
            icon: 'arrow_back',
            iconTarget: '/ctrls/:id',
            iconOnClick: clearUploadObj,
            button: true,
            buttonText: 'Speichern',
            buttonTarget: '/ctrls',
            buttonOnClick: uploadObjUpdate,
            component: <ControlPage users={users} user={user} showModal={showModal} closeModal={closeModal} action='edit' getLocalControl={getLocalControl} setLocalControl={setLocalControl} globalUploadObj={globalUploadObj} setGlobalUploadObj={setGlobalUploadObj}/>
        }
    ];

    if(user.loggedIn){
        return (
            <div>
                <Router>
                    <Modal data={modalData} modalVisible={modalVisible}/>
                    <Menu menuVisible={menuVisible} toggleMenu={toggleMenu} logout={logout} user={user}/>
                    <MenuCloser menuVisible={menuVisible} toggleMenu={toggleMenu}/>
                    <Switch>
                        {pages.map((page) => (
                            <Route path={page.url} key={page.id} exact>
                                <div className="page">
                                    <AppBar menuVisible={menuVisible} toggleMenu={toggleMenu} data={page}/>
                                    {page.component}
                                </div>
                            </Route>
                        ))}
                    </Switch>
                </Router>
            </div>
        );
    }
    else{
        return <LoginPage setControls={setControls} setUser={setUser} setUsers={setUsers}/>
    }
}
