import {linienbuendel} from './components/Linienbuendel'

export const ctrlSchema = [
    {
        id: 'plannedDate',
        label: 'Geplantes Datum',
        type: 'date',
        date: {
            min: new Date().toISOString().split('T')[0],
            placeholder: new Date().toISOString().split('T')[0]
        },
        showWhen: [
            'A',
            'B'
        ]
    },
    {
        id: 'owner',
        label: 'Verantwortlich',
        type: 'userSelect',
        showWhen: [
            'A',
            'B'
        ]
    },
    {
        id: 'linienbuendel',
        label: 'Linienbündel',
        type: 'select',
        options: linienbuendel,
        showWhen: [
            'A',
            'B'
        ]
    },{
        id: 'ctrlCategory',
        label: 'Kontroll-Kategorie',
        type: 'select',
        options: [
            {
                value: 'A',
                text: 'A'
            },
            {
                value: 'B',
                text: 'B'
            }
        ],
        showWhen: [
            'A',
            'B'
        ]
    },{
        id: 'vehicle',
        label: 'Fahrzeug-Typ',
        type: 'select',
        options: [
            {
                value: 'Bus',
                text: 'Bus'
            },
            {
                value: 'RE',
                text: 'RE'
            },
            {
                value: 'RB',
                text: 'RB'
            }
        ],
        showWhen: [
            'A',
            'B'
        ]
    },{
        id: 'licensePlate',
        label: 'Kennzeichen',
        type: 'text',
        placeholder: 'K-XY 1234',
        showWhen: [
            'A',
            'B'
        ]
    },{
        id: 'line',
        label: 'Linie',
        type: 'text',
        placeholder: '127',
        showWhen: [
            'A',
            'B'
        ]
    },{
        id: 'headingTo',
        label: 'Richtung',
        type: 'text',
        placeholder: 'Hunsrück Rathaus',
        showWhen: [
            'A',
            'B'
        ],
        autocomplete: {
            type: 'station'
        }
    },{
        id: 'startingIn',
        label: 'Kontroll-Start',
        type: 'text',
        placeholder: 'Koblenz Stadtmitte',
        showWhen: [
            'A',
            'B'
        ],
        autocomplete: {
            type: 'station'
        }
    },{
        id: 'endingIn',
        label: 'Kontroll-Ende',
        type: 'text',
        placeholder: 'Simmern Kirche',
        showWhen: [
            'A',
            'B'
        ],
        autocomplete: {
            type: 'station'
        }
    },{
        id: 'tripId',
        label: 'Fahrtnummer',
        type: 'text',
        placeholder: '59441',
        showWhen: [
            'A',
            'B'
        ]
    },{
        id: 'operator',
        label: 'Betreiber',
        type: 'text',
        placeholder: 'DB Regio AG NRW',
        showWhen: [
            'A',
            'B'
        ]
    },{
        id: 'workingTicketMachine',
        label: 'Defektes Fahrkartenverkaufsgerät / Fahrscheinentwerter',
        type: 'checkbox',
        showWhen: [
            'A',
            'B'
        ]
    },{
        id: 'workingWagenHaeltSign',
        label: '"Wagen hält"-Anzeige funktioniert nicht',
        type: 'checkbox',
        showWhen: [
            'A',
            'B'
        ]
    },{
        id: 'vehicleMeetsRequirements',
        label: 'Einsatz eines nicht den vereinbarten Standards entsprechenden Fahrzeugs',
        type: 'checkbox',
        showWhen: [
            'A',
            'B'
        ]
    },{
        id: 'hasWerbeplakat',
        label: 'Fehlendes VRM-Werbeplakat im Plakatrahmen',
        type: 'checkbox',
        showWhen: [
            'A'
        ]
    },{
        id: 'hasVRMLogo',
        label: 'Fehlende/veraltete VRM-Logos',
        type: 'checkbox',
        showWhen: [
            'A'
        ]
    },{
        id: 'hasCorrectSignage',
        label: 'Fehlende/unzureichende/falsche Beschilderung des Fahrzeugs',
        type: 'checkbox',
        showWhen: [
            'A',
            'B'
        ]
    },{
        id: 'intactInterior',
        label: 'Gravierende Schadhaftigkeit der Inneneinrichtung des Fahrzeugs',
        type: 'checkbox',
        showWhen: [
            'A',
            'B'
        ]
    },{
        id: 'cleanInterior',
        label: 'Gravierende Verschmutzung im Fahrzeuginneren',
        type: 'checkbox',
        showWhen: [
            'A',
            'B'
        ]
    },{
        id: 'workingAirCondition',
        label: 'Fehlende/unzureichende Belüftung',
        type: 'checkbox',
        showWhen: [
            'A',
            'B'
        ]
    },{
        id: 'tripCancelledWithoutReplacement',
        label: 'Fahrtausfall ohne Ersatzbeförderung',
        type: 'checkbox',
        showWhen: [
            'A',
            'B'
        ]
    },{
        id: 'tripCancelledWithReplacement',
        label: 'Fahrtausfall mit Ersatzbeförderung',
        type: 'checkbox',
        showWhen: [
            'A',
            'B'
        ]
    },{
        id: 'inexperiencedStaff',
        label: 'Einsatz streckenunkundigen Fahrpersonals',
        type: 'checkbox',
        showWhen: [
            'A',
            'B'
        ]
    },{
        id: 'staffSmoking',
        label: 'Personal raucht im Fahrzeug',
        type: 'checkbox',
        showWhen: [
            'A',
            'B'
        ]
    },{
        id: 'assistanceProvided',
        label: 'Unterlassene Hilfestellung für hilfsbedürftige Personen',
        type: 'checkbox',
        showWhen: [
            'A',
            'B'
        ]
    },{
        id: 'informationAvailable',
        label: 'Fehlen der Informationsmedien oder Verweigern der gewünschten Informationen durch den Fahrer',
        type: 'checkbox',
        showWhen: [
            'A',
            'B'
        ]
    },{
        id: 'staffOnPhoneWithoutEquipment',
        label: 'Personal telefoniert während der Fahrt ohne Freisprecheinrichtung',
        type: 'checkbox',
        showWhen: [
            'A',
            'B'
        ]
    },{
        id: 'notes',
        label: null,
        type: 'textarea',
        placeholder: 'Notizen...',
        showWhen: [
            'A',
            'B'
        ]
    }

]
export const hsCtrlSchema = [
    {
        id: 'name',
        label: null,
        type: 'hsName',
        placeholder: 'Haltestelle...',
        showWhen: [
            'A',
            'B'
        ],
        autocomplete: {
            type: 'station'
        }
    },{
        id: 'linienbuendel',
        label: 'Linienbündel',
        type: 'select',
        options: linienbuendel,
        showWhen: [
            'A',
            'B'
        ]
    },{
        id: 'hasVRMLogo',
        label: 'Fehlendes/veraltetes VRM-Logo',
        type: 'checkbox',
        showWhen: [
            'A',
            'B'
        ]
    },{
        id: 'hasZielAnzeige',
        label: 'Fehlende/falsche Liniennummer / Linienverlauf / Zielangabe',
        type: 'checkbox',
        showWhen: [
            'A',
            'B'
        ]
    },{
        id: 'hasLinienNetzPlan',
        label: 'Fehlender Liniennetzplan',
        type: 'checkbox',
        showWhen: [
            'A',
            'B'
        ]
    },{
        id: 'hasFahrplan',
        label: 'Fehlender Fahrplan',
        type: 'checkbox',
        showWhen: [
            'A',
            'B'
        ]
    },{
        id: 'hasHSchild',
        label: 'Fehlendes H-Schild',
        type: 'checkbox',
        showWhen: [
            'A',
            'B'
        ]
    },{
        id: 'hasBezeichnung',
        label: 'Fehlende H-Bezeichnung',
        type: 'checkbox',
        showWhen: [
            'A',
            'B'
        ]
    },{
        id: 'hasWerbeplakat',
        label: 'Fehlendes Werbeplakat',
        type: 'checkbox',
        showWhen: [
            'A',
            'B'
        ]
    },{
        id: 'noStopDespiteWaitingPassengers',
        label: 'Nicht-Bedienen einer Haltestelle trotz ein- oder ausstiegswilliger Fahrgäste',
        type: 'checkbox',
        showWhen: [
            'A',
            'B'
        ]
    },{
        id: 'notes',
        label: null,
        type: 'textarea',
        placeholder: 'Notizen...',
        showWhen: [
            'A',
            'B'
        ]
    }
]
export const hsCardSchema = [
    {
        id: 'name',
        label: null,
        type: 'hsName',
        placeholder: 'Haltestelle...',
        showWhen: [
            'A',
            'B'
        ],
        autocomplete: {
            type: 'station'
        }
    },{
        id: 'plannedDeparture',
        label: 'Abfahrt soll',
        type: 'text',
        placeholder: '12:00',
        showWhen: [
            'A',
            'B'
        ]
    },{
        id: 'actualDeparture',
        label: 'Abfahrt ist',
        type: 'text',
        placeholder: '12:00',
        showWhen: [
            'A',
            'B'
        ]
    },{
        id: 'hasVRMLogo',
        label: 'Fehlendes/veraltetes VRM-Logo',
        type: 'checkbox',
        showWhen: [
            'A',
            'B'
        ]
    },{
        id: 'hasZielAnzeige',
        label: 'Fehlende/falsche Liniennummer / Linienverlauf / Zielangabe',
        type: 'checkbox',
        showWhen: [
            'A',
            'B'
        ]
    },{
        id: 'missingOrWrongStopAnnouncement',
        label: 'Keine/falsche Haltestellenansage',
        type: 'checkbox',
        showWhen: [
            'A',
            'B'
        ]
    },{
        id: 'missingOrWrongStopDisplay',
        label: 'Keine/falsche Haltestellenanzeige',
        type: 'checkbox',
        showWhen: [
            'A'
        ]
    },{
        id: 'hasLinienNetzPlan',
        label: 'Fehlender Liniennetzplan',
        type: 'checkbox',
        showWhen: [
            'A',
            'B'
        ]
    },{
        id: 'hasFahrplan',
        label: 'Fehlender Fahrplan',
        type: 'checkbox',
        showWhen: [
            'A',
            'B'
        ]
    },{
        id: 'hasHSchild',
        label: 'Fehlendes H-Schild',
        type: 'checkbox',
        showWhen: [
            'A',
            'B'
        ]
    },{
        id: 'hasBezeichnung',
        label: 'Fehlende H-Bezeichnung',
        type: 'checkbox',
        showWhen: [
            'A',
            'B'
        ]
    },{
        id: 'hasWerbeplakat',
        label: 'Fehlendes Werbeplakat',
        type: 'checkbox',
        showWhen: [
            'A',
            'B'
        ]
    },{
        id: 'noStopDespiteWaitingPassengers',
        label: 'Nicht-Bedienen einer Haltestelle trotz ein- oder ausstiegswilliger Fahrgäste',
        type: 'checkbox',
        showWhen: [
            'A',
            'B'
        ]
    },{
        id: 'notes',
        label: null,
        type: 'textarea',
        placeholder: 'Notizen...',
        showWhen: [
            'A',
            'B'
        ]
    }]
