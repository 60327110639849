import React, {useState, useEffect} from 'react';
import HsCard from './HsCard';
import FormEngine from './FormEngine';
import {ctrlSchema} from '../Schema'
export default function Ctrl(props) {
    let id = props.id;
    let action = props.action;
    let controls = props.controls;
    let users = props.users;
    let globalUploadObj = props.globalUploadObj;
    let setGlobalUploadObj = props.setGlobalUploadObj;
    let showModal = props.showModal;
    let closeModal = props.closeModal;
    let stopObj = props.stopObj;
    let localControl = props.localControl;
    let setLocalControl = props.setLocalControl;
    let ctrlType = props.ctrlType;
    let data;


    const [tabState, setTabState] = useState(0);
    let tabViewClasses;
    tabState === 0 ? tabViewClasses={tabViewContent: "tabViewContent", tabViewButton0: "tabViewButton tabViewButtonActive noselect pointer", tabViewButton1: "tabViewButton noselect pointer", tabViewActiveBar: "tabViewActiveBar"} : tabViewClasses={tabViewContent: "tabViewContent tabViewContent1", tabViewButton0: "tabViewButton noselect pointer", tabViewButton1: "tabViewButton tabViewButtonActive noselect pointer", tabViewActiveBar: "tabViewActiveBar tabViewActiveBar1"};


    const setStop = (index,data,id) => {
        if(action==='view'){
            let tempLocalControl = localControl;
            tempLocalControl.stops[index] = data;
            setLocalControl(id,tempLocalControl)
        }
        else{
            let tempGlobalUploadObj = {...globalUploadObj};
            tempGlobalUploadObj.obj.stops[index] = data;
            setGlobalUploadObj(tempGlobalUploadObj);
        }
    }
    const addStopCard = () => {
        let tempGlobalUploadObj = {...globalUploadObj};
        tempGlobalUploadObj.obj.stops.push(stopObj);
        setGlobalUploadObj(tempGlobalUploadObj);
    }
    const deleteStop = (index) => {
        let tempGlobalUploadObj = {...globalUploadObj};
        tempGlobalUploadObj.obj.stops.splice(index, 1);
        setGlobalUploadObj(tempGlobalUploadObj);
        closeModal();
    }
    const handleChange = (e) => {
        let tempGlobalUploadObj = {...globalUploadObj};
        if(e.target.id === 'owner'){
            tempGlobalUploadObj.obj[e.target.id] = {user_id: e.target.value};
        }
        else{
            tempGlobalUploadObj.obj[e.target.id] = e.target.value;
        }
        if(e.target.id == 'linienbuendel'){
            tempGlobalUploadObj.obj.operator = ctrlSchema.find(y=>y.id==='linienbuendel').options.find(x=>x.value===e.target.value).operator;
        }
        setGlobalUploadObj(tempGlobalUploadObj);
    }
    const handleCheck = (e) => {
        let tempGlobalUploadObj = {...globalUploadObj};
        tempGlobalUploadObj.obj[e.target.id] = e.target.checked;
        console.log(e.target.id)
        setGlobalUploadObj(tempGlobalUploadObj);
    }


    useEffect(()=>{
        let tempGlobalUploadObj = {...globalUploadObj}
        if(globalUploadObj.obj.ctrlCategory==='B'){
            tempGlobalUploadObj.obj['hasVRMLogo'] = null;
            tempGlobalUploadObj.obj['hasWerbeplakat'] = null;
        }
        else{
            tempGlobalUploadObj.obj['hasVRMLogo'] = false;
            tempGlobalUploadObj.obj['hasWerbeplakat'] = false;
        }
        setGlobalUploadObj(tempGlobalUploadObj);
    },[globalUploadObj.obj.ctrlCategory])



    if(action==='view'){
        if(localControl){
            data = localControl;
        }
        else{
            alert('Hier ist ein Fehler')
        }
    }
    else if(action==='edit'){
        if(globalUploadObj.id!==id){
            setGlobalUploadObj({
                id: id,
                type: localControl.type,
                obj: localControl,
                action: 'wait'
            })
        }
        data = globalUploadObj.obj;
    }
    else{
        data = globalUploadObj.obj;
    }

        if(ctrlType==='ctrl'&&data.stops){
            return(
                <div className="content">
                    <div className="tabViewButtonBar">
                        <div className={tabViewClasses.tabViewButton0} onClick={()=>{setTabState(0)}}>Fahrzeug</div>
                        <div className={tabViewClasses.tabViewButton1} onClick={()=>{setTabState(1)}}>Haltestellen</div>
                    </div>
                    <div className={tabViewClasses.tabViewActiveBar}></div>
                        <div className="divider"></div>
                    <div className="tabViewPos">
                        <div className={tabViewClasses.tabViewContent}>
                            <div className="tabViewPage">
                                <FormEngine users={users} data={data} ctrlType="ctrl" action={action} handleChange={handleChange} handleCheck={handleCheck} ctrlCategory={data.ctrlCategory}/>
                            </div>
                            <div className="tabViewPage">
                                {data.stops.map((stop, index) => (
                                    <HsCard id={data.id} stop={stop} key={index} index={index} deleteStop={deleteStop} setStop={setStop} showModal={showModal} closeModal={closeModal} action={action} ctrlCategory={data.ctrlCategory}/>
                                ))}
                                {action === 'view' ? <span></span> : <button onClick={addStopCard} className="extraButton noselect pointer">Haltestelle hinzufügen</button>}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else if(ctrlType==='hsctrl'){
            return(
                <div className="content">
                    <FormEngine users={users} data={data} ctrlType="hsctrl" action={action} handleChange={handleChange} handleCheck={handleCheck} ctrlCategory={data.ctrlCategory}/>
                </div>
            )
        }
        else{
            console.log('lädt ctrl')
            return <div>lädt...</div>
        }
}
