import React from 'react';
import CtrlList from '../CtrlList';

export default function HomePage(props) {
    let controls = props.controls;
    let setControls = props.setControls;
    let showModal = props.showModal;
    let closeModal = props.closeModal;
    let update = props.update;
    let deleteC = props.deleteC;
    let setGlobalUploadObj = props.setGlobalUploadObj;
    let user = props.user;
    let myControls = []

    controls.forEach((control)=>{
        if(control.owner.user_id === user._id){
            myControls.push(control)
        }
    })

    return (
        <CtrlList controls={myControls} setControls={setControls} showModal={showModal} closeModal={closeModal} update={update} setGlobalUploadObj={setGlobalUploadObj} deleteC={deleteC} user={user} noCtrlsText="Ihnen wurde noch keine Kontrolle zugewiesen."/>
    );
}
