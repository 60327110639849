import React from 'react';
import {Link} from "react-router-dom";
export default function Menu(props) {
    let user = props.user;
    let menuClass;
    props.menuVisible ? menuClass = 'menu' : menuClass = 'menuHidden';
    return (
        <div className={menuClass}>
            <Link to="/" className="menuLink noselect" onClick={props.logout}>Abmelden</Link>
            <Link to="/hsctrl/new" className="menuLink noselect" onClick={props.toggleMenu}>Haltestelle melden</Link>
            <Link to="/ctrls" className="menuLink noselect" onClick={props.toggleMenu}>Alle Kontrollen</Link>
            <Link to="/info" className="menuLink noselect" onClick={props.toggleMenu}>Information</Link>
            {user.role === 'ADMIN' ? <Link to="/admin" className="menuLink noselect" onClick={props.toggleMenu}>Admin-Panel</Link> : <></>}
        </div>
    );
}
